<template>
  <default>
    <div class="tw-mb-4 c-blue-text">
      <v-chip-group mandatory active-class="primary">
        <v-chip active-class="false" :to="{ name: 'Dashboard' }" class="tw-bg-blue-100 tw-text-blue-700 tw-rounded-md">
          Dashboard
        </v-chip>
        <span class="mt-2 mx-2"> > </span>

        <v-chip :to="{ name: 'AgentGuide' }" class="tw-bg-blue-100 tw-text-blue-700 tw-rounded-md">
          {{ $options.name }}
        </v-chip>
      </v-chip-group>

      <div class="tw-mt-12 tw-flex tw-justify-end">
        <div class="tw-w-full sm:tw-w-1/3 md:tw-w-1/4">
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search proposals" single-line hide-details
            outlined dense rounded color="primary"
            class="tw-shadow-md tw-border-0 tw-bg-gray-100 hover:tw-bg-gray-50 focus:tw-bg-white transition-colors"></v-text-field>
        </div>
      </div>

      <hr class="tw-my-6 tw-border-gray-200" />

      <v-data-table :headers="headers" :items="proposals" :search="search" sort-by="activePolEffectiveDate"
        class="elevation-1 tw-rounded-lg tw-shadow-lg tw-border tw-border-gray-200" :page.sync="pagination.pageNumber"
        :items-per-page.sync="pagination.perPage" :loading="loadingTable" :footer-props="{
          itemsPerPageOptions: [10, 20, 30, 40, 50],
          showCurrentPage: true,
        }">
        <template v-slot:[`item.activePolSA`]="{ item }">
          <span class="tw-text-sm tw-text-gray-800">{{ item.activePolSA | formatNum }}</span>
        </template>
        <template v-slot:[`item.activePolPremium`]="{ item }">
          <span class="tw-text-sm tw-text-gray-800">{{ item.activePolPremium | formatNum }}</span>
        </template>
        <template v-slot:[`item.activePolEffectiveDate`]="{ item }">
          <span class="tw-text-sm tw-text-gray-600">{{ item.activePolEffectiveDate | formatToHuman }}</span>
        </template>
      </v-data-table>
    </div>
  </default>
</template>

<script>
import Default from '../../layouts/Default';
import proposalsMixins from '@/mixins/proposalsMixins';

export default {
  name: 'Proposals',
  metaInfo: {
    title: 'Proposals documents',
  },
  components: {
    Default,
  },
  mixins: [proposalsMixins],
  data() {
    return {
      loadingTable: true,
      policyType: 'proposal',
    };
  },
};
</script>

<style scoped>
.v-chip {
  font-weight: 500;
  padding: 0.3rem 1rem;
  transition: background-color 0.2s;
}

.v-chip:hover {
  background-color: #e0f2ff !important;
}

.v-text-field input {
  font-size: 0.875rem;
  padding: 0.75rem;
}

.v-data-table {
  border-radius: 0.75rem;
  overflow: hidden;
}

.v-data-table th {
  background-color: #f3f4f6;
  color: #374151;
  font-weight: 600;
  font-size: 0.9rem;
}

.v-data-table tbody tr:hover {
  background-color: #f9fafb;
}
</style>
